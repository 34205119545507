.grid_map::before {
  content: "";
  width: 100%;
  top: 0;
  height: 4px;
  background: linear-gradient(90deg, transparent, rgba(18, 64, 118, 0.5), transparent);
}

.grid_map {
  width: 100%;
  min-height: 700px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  background-attachment: scroll, scroll;
  -webkit-background-clip: border-box, border-box;
  background-blend-mode: normal, normal;
  background-image: url(../../assets/img/svg/grid.svg), radial-gradient(50% 80% at 50% 0%, rgba(18, 64, 118, 0.5) 0%, rgba(17, 24, 28, 0) 100%);
  background-origin: padding-box, padding-box;
  background-position: 50% 0%, 0%;
  background-repeat: no-repeat, repeat;
  background-size: contain, auto;
}

.container_grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.container_grid .map_steep {
  width: 100%;
}
.container_grid .map_steep:last-child .circle::before,
.container_grid .map_steep:last-child .circl {
  background: #fff;
}
.container_grid .map {
  display: flex;
  position: relative;
}
@media screen and (min-width: 0px) {
  .container_grid .map {
    width: 90%;
  }
}
@media screen and (min-width: 700px) {
  .container_grid .map {
    width: 600px;
  }
}
@media screen and (min-width: 0px) {
  .container_grid h3 {
    font-size: 17px;
  }
}
@media screen and (min-width: 700px) {
  .container_grid h3 {
    font-size: 19px;
  }
}
.container_grid .description {
  opacity: 0.8;
}
@media screen and (min-width: 0px) {
  .container_grid .description {
    font-size: 12px;
  }
}
@media screen and (min-width: 700px) {
  .container_grid .description {
    font-size: 14px;
  }
}
.container_grid .line {
  width: 4px;
  height: 82%;
  background: linear-gradient(#003ef7, #003ef7, #ffffff);
  position: absolute;
  display: flex;
  top: 5px;
  border: 1px solid rgba(255, 255, 255, 0.6588235294);
}
@media screen and (min-width: 0px) {
  .container_grid .line {
    left: 10.5px;
  }
}
@media screen and (min-width: 700px) {
  .container_grid .line {
    left: 10.5px;
  }
}
.container_grid .circle::before {
  content: "";
  width: 60%;
  height: 60%;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 47%;
  left: 47%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #2a5ae9;
}
.container_grid .circle {
  background: #fff;
  position: relative;
  border-radius: 50%;
  display: flex;
  transform: translateY(5px);
  justify-content: center;
  align-items: center;
  color: transparent;
  box-shadow: 0 0 10px #2a5ae9;
}
@media screen and (min-width: 0px) {
  .container_grid .circle {
    width: 25px;
    height: 25px;
  }
}
@media screen and (min-width: 700px) {
  .container_grid .circle {
    width: 25px;
    height: 25px;
  }
}
.container_grid h1 {
  color: transparent;
  background: linear-gradient(45deg, #f0f0f0, #1e71ef, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}/*# sourceMappingURL=SteepsCreate.module.css.map */