.boxInfo {
  background: rgba(50, 51, 53, 0.248);
  height: 100%;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6156862745);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 1;
  border: 1px solid #222;
}
.boxInfo h3 {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
@media screen and (min-width: 0px) {
  .boxInfo h3 {
    font-size: 15px;
  }
}
@media screen and (min-width: 767.98px) {
  .boxInfo h3 {
    font-size: 20px;
  }
}

.card_tools {
  background: #fff;
}/*# sourceMappingURL=Main.module.css.map */