.grid_map::before{
    content: "";
    width: 100%;
    top: 0;
    height: 4px;
    background: linear-gradient(90deg, transparent, rgb(18 64 118 / 50%), transparent);
}
.grid_map{
    width: 100%;
    min-height: 700px;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    background-attachment: scroll,scroll;
    -webkit-background-clip: border-box,border-box;
    background-blend-mode: normal,normal;
    background-image: url(../../assets/img/svg/grid.svg),radial-gradient(50% 80%at 50% 0%,rgb(18 64 118 / 50%) 0%,rgba(17,24,28,0) 100%);
    background-origin: padding-box,padding-box;
    background-position: 50% 0%,0%;
    background-repeat: no-repeat,repeat;
    background-size: contain,auto;
}


.container_grid{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    .map_steep{
        width: 100%;
    }

    .map_steep:last-child{

        .circle::before,
        .circl{
            background: #fff;
        }
    }

    .map{
        @media screen and (min-width: 0px) {
            width: 90%;
        }
        @media screen and (min-width: 700px) {
            width: 600px;
        }
        display: flex;
        position: relative;
        
    }

    h3{
        @media screen and (min-width: 0px) {
            font-size: 17px;
        }
        @media screen and (min-width: 700px) {
            font-size: 19px;
        }
    }

    .description{
        opacity: 0.8;
        @media screen and (min-width: 0px) {
            font-size: 12px;
        }
        @media screen and (min-width: 700px) {
            font-size: 14px;
        }
    }

    .line{
        width: 4px;
        height: 82%;
        background: linear-gradient(#003ef7, #003ef7, #ffffff);
        position: absolute;
        display: flex;
        @media screen and (min-width: 0px) {
            left: 10.5px;
        }
        @media screen and (min-width: 700px) {
            left: 10.5px;
        }
        
        top: 5px;
        border: 1px solid #ffffffa8;
    }

    .circle::before{
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        display: flex;
        position: absolute;
        top: 47%;
        left: 47%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background: #2a5ae9;
    }
    .circle{
        @media screen and (min-width: 0px) {
            width: 25px;
            height: 25px;
        }
        @media screen and (min-width: 700px) {
            width: 25px;
            height: 25px;
        }
        background: #fff;
        position: relative;
        border-radius: 50%;
        display: flex;
        transform: translateY(5px);
        justify-content: center;
        align-items: center;
        color: transparent;
        box-shadow:  0 0 10px #2a5ae9;
    }

    h1{
        color: transparent;
        background: linear-gradient(45deg, #f0f0f0, #1e71ef, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


