@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.header {
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  transition: none !important;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
}
.header .header_blur {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.418);
  margin-bottom: 30px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.header .sav_content_box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5019607843), rgba(255, 255, 255, 0.5019607843), transparent);
  bottom: 0;
}
.header .sav_content_box {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 0px) {
  .header .sav_content_box {
    padding: 0 20px;
  }
}
@media screen and (min-width: 576.98px) {
  .header .sav_content_box {
    padding: 0;
  }
}
.header .logo {
  font-size: 20px;
  font-family: "Open Sans";
  color: #fff;
  font-weight: 500;
}
.header .bg_box {
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: none !important;
  display: none;
  position: absolute;
  margin: 0;
  background: linear-gradient(45deg, #19458b, #0d0d0e);
  background-size: auto;
  background-size: 200% 200%;
  animation: Header_gradientBG__-gid4 25s ease infinite;
  border-radius: 0 0 50px 50px;
  box-shadow: 0 0 20px #000000;
}
.header .sav_request {
  font-size: 14px;
  white-space: nowrap;
}

.language:hover .list_language {
  display: flex !important;
  top: 100%;
  transition: 0.3s ease;
}

.language {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid transparent;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.language .active_lang {
  box-shadow: 0 0 4px rgba(19, 19, 19, 0.6509803922);
  transform: scale(1.1);
}
.language .list_language::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid #0583eb;
}
.language .list_language {
  width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 60%;
  right: 0;
  transition: 0.3s ease;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(53, 50, 50, 0.54);
  -webkit-backdrop-filter: flur(5px);
  backdrop-filter: flur(5px);
  padding: 10px 5px;
  gap: 0.3em;
  border: 1px solid #0583eb;
}
.language .list_language button {
  width: 20px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  border: none;
  background: transparent;
}
.language .list_language .flag_en {
  position: relative;
  background: url(../../assets/img/flag/en.png) center/cover no-repeat;
}
.language .list_language .flag_ua {
  position: relative;
  background: url(../../assets/img/flag/ua.png) center/cover no-repeat;
}
.language svg {
  width: 20px;
  height: 20px;
}
.language svg path {
  fill: #fff;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.line_header {
  width: 50px;
  height: 4px;
  border-radius: 10px;
  background: #0583eb;
}

.btn_line {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (min-width: 0px) {
  .btn_line {
    gap: 20px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 400px) {
  .btn_line {
    gap: 30px;
    margin-top: 20px;
  }
}

.order {
  border-radius: 10px;
  border: none;
  text-decoration: none;
  background: transparent;
  font-weight: 600;
  color: #fff;
}
@media screen and (min-width: 0px) {
  .order {
    padding: 10px 0;
  }
}
@media screen and (min-width: 767.98px) {
  .order {
    padding: 10px 30px;
  }
}

.order_bg:hover {
  transition: 0.3s ease;
  opacity: 0.8;
  background: transparent;
}

.order_bg {
  padding: 10px 30px;
  border-radius: 10px;
  border: 2px solid #0583eb;
  transition: 0.3s ease;
  background: #0583eb;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  display: flex;
  position: relative;
}
.order_bg span {
  position: absolute;
  top: 0;
  display: flex;
}
@media screen and (min-width: 0px) {
  .order_bg span {
    transform: rotate(-40deg) translateY(-35px) translateX(-26px);
  }
}
@media screen and (min-width: 450px) {
  .order_bg span {
    transform: rotate(-20deg) translateY(-55px) translateX(-53px);
  }
}
.order_bg span svg {
  width: 50px;
  height: 50px;
}

.header_general {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  position: relative;
}
.header_general h1 {
  max-width: 400px !important;
  text-align: left !important;
  font-family: "Oswald", sans-serif !important;
}
@media screen and (min-width: 0px) {
  .header_general h1 {
    font-size: 25px !important;
    margin-top: 10px;
  }
}
@media screen and (min-width: 320px) {
  .header_general h1 {
    font-size: 45px !important;
    margin-top: 0px;
  }
}
@media screen and (min-width: 400px) {
  .header_general h1 {
    font-size: 55px !important;
  }
}
@media screen and (min-width: 700px) {
  .header_general h1 {
    font-size: 65px !important;
  }
}
.header_general .dsc {
  max-width: 400px !important;
  text-align: left !important;
  opacity: 0.8;
}
@media screen and (min-width: 0px) {
  .header_general .dsc {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 400px) {
  .header_general .dsc {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 0px) {
  .header_general a {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 400px) {
  .header_general a {
    font-size: 17px !important;
  }
}

.header_content {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  z-index: 1;
  border-radius: 0 0 20px 20px;
}
@media screen and (min-width: 0px) {
  .header_content {
    padding: 0 20px 30px 20px;
  }
}
@media screen and (min-width: 576.98px) {
  .header_content {
    padding: 0px 0px 30px 0;
  }
}
.header_content .header_box {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.header_content .content_abs {
  width: 100%;
  height: 100%;
  min-height: 600px;
  border-radius: 18px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 0px) {
  .header_content .content_abs {
    padding: 0px;
  }
}
@media screen and (min-width: 1000px) {
  .header_content .content_abs {
    padding: 50px;
  }
}
.header_content h1 {
  transform: translateZ(100px);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  background-image: linear-gradient(180deg, #ffffff, #ffffff);
  color: transparent;
  background-clip: text;
  font-family: "Permanent Marker", cursive;
  -webkit-background-clip: text;
  text-align: center;
}
@media screen and (min-width: 0px) {
  .header_content h1 {
    font-size: 60px;
  }
}
@media screen and (min-width: 576.98px) {
  .header_content h1 {
    font-size: 90px;
  }
}
.header_content h4 {
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 0px) {
  .header_content h4 {
    font-size: 15px;
  }
}
@media screen and (min-width: 576.98px) {
  .header_content h4 {
    font-size: 20px;
  }
}
.header_content .card_holder {
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
}
.header_content .card_holder h4 {
  width: 70%;
  text-align: center;
}
.header_content .contact::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: 0s ease;
  background: linear-gradient(50deg, transparent, transparent, rgba(255, 255, 255, 0.4039215686), transparent, transparent);
  display: flex;
  position: absolute;
}
.header_content .contact:hover::before {
  left: 100%;
  transition: 0.8s ease;
}
.header_content .contact:hover {
  transition: 0.4s ease;
  transform: translateY(-5px);
}
.header_content .contact {
  height: 40px;
  position: relative;
  overflow: hidden;
  padding: 5px 20px;
  background: transparent;
  transition: 0.4s ease;
  border-radius: 20px;
  border: 2px solid #fff;
  color: #fff;
}
.header_content .contact span {
  position: relative;
  z-index: 1;
}
.header_content .welcome_img {
  height: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header_content .welcome_img img {
  width: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=Header.module.css.map */