@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.footer {
  width: 100%;
  display: flex;
  height: 5%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  bottom: 0;
}
.footer .bg_box {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: none !important;
  display: flex;
  position: absolute;
  margin: 0;
  background: linear-gradient(-45deg, #de6945, #da2f71, #1c97c3, #19c19a);
  background-size: 400% 400%;
  animation: gradientBG 25s ease infinite;
  box-shadow: 0 0 20px #000000;
}
.footer .footer_content {
  width: 100%;
  height: 100%;
}

.copy::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
}

.adaptive_line {
  align-items: center;
  display: flex;
}
@media screen and (min-width: 0px) {
  .adaptive_line {
    flex-direction: column;
  }
}
@media screen and (min-width: 600px) {
  .adaptive_line {
    flex-direction: row;
  }
}

.copy {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
  font-size: 14px;
  color: #fff;
}
.copy a:hover {
  color: rgb(0, 0, 0);
}

.footer_content {
  width: 100%;
  z-index: 1;
  position: relative;
}/*# sourceMappingURL=Footer.module.css.map */