.boxInfo{
    background: rgba(50, 51, 53, 0.248);
    height: 100%;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 5px 10px #0000009d;
    backdrop-filter: blur(2px);
    z-index: 1;
    border: 1px solid #222;

    h3{
        width: 100%;
        margin-top: 15px;
        @media screen and (min-width: 0px){
            font-size: 15px;
        }
        @media screen and (min-width: 767.98px){
            font-size: 20px;
        }
        text-align: center;
    }

}

.card_tools{
    background: #fff;
}
