

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@keyframes gradientBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
}

@mixin mq($size, $direction: min-width, $media-type: screen) {
@media #{$media-type} and (#{$direction}: $size) {
    @content;
}
}

$bg_button: #0583eb;

.header{
    width: 100%;
    max-width: 100vw;
    display: flex;
    align-items: center;
    transition: none!important;
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;

    .header_blur{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.418);
        margin-bottom: 30px;
        backdrop-filter: blur(5px);
    }

    .sav_content_box::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent, #ffffff80, #ffffff80, transparent);
        bottom: 0;
    }
    .sav_content_box{
        width: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        height: 100%;
        @media screen and (min-width: 0px){
            padding: 0 20px;
        }
        @media screen and (min-width: 576.98px){
            padding: 0;
        }
        margin-bottom: 20px;
    }
    
    .logo{
        font-size: 20px;
        font-family: "Open Sans";
        color: #fff;
        font-weight: 500;
    }

    .bg_box{
        top: -20px;
        left: 0;
        width: 100%;
        height: 100%;
        transition: none !important;
        display: none;
        position: absolute;
        margin: 0;
        background: linear-gradient(45deg, #19458b, #0d0d0e);
        background-size: auto;
        background-size: 200% 200%;
        animation: Header_gradientBG__-gid4 25s ease infinite;
        border-radius: 0 0 50px 50px;
        box-shadow: 0 0 20px #000000;
    }

    .sav_request{
        font-size: 14px;
        white-space: nowrap;
    }
}


.language:hover{
    
    .list_language{
        display: flex!important;
        top: 100%;
        transition: 0.3s ease;
    }
}
.language{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 10px;
    border: 1px solid transparent;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .active_lang{
        box-shadow: 0 0 4px #131313a6;
        transform: scale(1.1);
    }

    .list_language::before {
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 6px solid $bg_button;
    }

    .list_language{
        width: 100%;
        border-radius: 5px;
        position: absolute;
        top: 60%;
        right: 0;
        transition: 0.3s ease;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(53, 50, 50, 0.54);
        -webkit-backdrop-filter: flur(5px);
        backdrop-filter: flur(5px);
        padding: 10px 5px;
        gap: 0.3em;
        border: 1px solid $bg_button;

        button{
            width: 20px;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            font-size: 12px;
            border: none;
            background: transparent;
        }

        .flag_en{
            position: relative;
            background: url(../../assets/img/flag/en.png) center / cover no-repeat;
        }
        .flag_ua{
            position: relative;
            background: url(../../assets/img/flag/ua.png) center / cover no-repeat;
        }
    }

    svg{
        width: 20px;
        height: 20px;

        path{
            fill: #fff;
        }
    }
}

@keyframes gradientBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
  }

.line_header{
    width: 50px;
    height: 4px;
    border-radius: 10px;
    background: $bg_button;
}

.btn_line{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   
    @include mq(0px){ gap: 20px; margin-top: 10px;}
    @include mq(400px){ gap: 30px; margin-top: 20px;}
    
}

.order{
    @include mq(0px){padding: 10px 0;}
    @include mq(767.98px){padding: 10px 30px;}
    border-radius: 10px;
    border: none;
    // border: 2px solid $bg_button;
    text-decoration: none;
    background: transparent;
    font-weight: 600;
    color: #fff;
}

.order_bg:hover{
    transition: 0.3s ease;
    opacity: 0.8;
    background: transparent;
}

.order_bg{
    padding: 10px 30px;
    border-radius: 10px;
    border: 2px solid $bg_button;
    transition: 0.3s ease;
    background: $bg_button;
    text-decoration: none;
    font-weight: 600;
    color: #fff;
    display: flex;
    position: relative;

    span{
        position: absolute;
        top: 0;
        @include mq(0px){transform: rotate(-40deg) translateY(-35px) translateX(-26px);}
        @include mq(450px){transform: rotate(-20deg) translateY(-55px) translateX(-53px);}
        display: flex;

        svg{
            width: 50px;
            height: 50px;
        }
    }
}


.header_general{
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 100;
    position: relative;

    h1{
        max-width: 400px!important;
        @include mq(0px){font-size: 25px!important; margin-top: 10px;}
        @include mq(320px){font-size: 45px!important; margin-top: 0px}
        @include mq(400px){font-size: 55px!important;}
        @include mq(700px){font-size: 65px!important;}
        text-align: left!important;
        font-family: 'Oswald', sans-serif!important;
        // font-family: 'Poppins', sans-serif;
    }

    .dsc{
        max-width: 400px!important;
        @include mq(0px){font-size: 12px!important;}
        @include mq(400px){font-size: 15px!important;}
        text-align: left!important;
        opacity: 0.8;
    }

    a{
        @include mq(0px){font-size: 12px!important;}
        @include mq(400px){font-size: 17px!important;}
    }
}

.header_content{
    height: 100%;
    position: relative;
    width: 100%;
    display: flex;
    z-index: 1;

    @media screen and (min-width: 0px) {
        padding: 0 20px 30px 20px;
    }
    @media screen and (min-width: 576.98px) {
        padding: 0px 0px 30px 0;
    }
    border-radius: 0 0 20px 20px;

    .header_box{
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .content_abs{
        width: 100%;
        height: 100%;
        min-height: 600px;
        @include mq(0px){padding: 0px;}
        @include mq(1000px){padding: 50px;}
        border-radius: 18px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    h1{
        transform: translateZ(100px);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        @media screen and (min-width: 0px) {
            font-size: 60px;
        }
        @media screen and (min-width: 576.98px) {
            font-size: 90px;
        }
        background-image: linear-gradient(180deg, #ffffff, #ffffff);
        color: transparent;
        background-clip: text;
        font-family: 'Permanent Marker', cursive;
        -webkit-background-clip: text;
        text-align: center;
        
    }

    h4{
        color: #fff;
        
        @media screen and (min-width: 0px) {
            font-size: 15px;
        }
        @media screen and (min-width: 576.98px) {
            font-size: 20px;
        }
        text-align: center;
    }

    .card_holder{
        display: flex;
        flex-direction: column;
        // background: rgba(0, 0, 0, 0.36);
        backdrop-filter: blur(5px);
        border-radius: 20px;
        padding: 20px;

        h4{
            width: 70%;
            text-align: center;
        }
    }

    .contact::before{
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: -100%;
        transition: 0s ease;
        background: linear-gradient(50deg, transparent, transparent, #ffffff67, transparent, transparent);
        display: flex;
        position: absolute;
    }

    .contact:hover::before{
        left: 100%;
        transition: 0.8s ease;
    }

    .contact:hover{
        transition: 0.4s ease;
        transform: translateY(-5px);
    }

    .contact{
        height: 40px;
        position: relative;
        overflow: hidden;
        padding: 5px 20px;
        background: transparent;
        transition: 0.4s ease;
        border-radius: 20px;
        border: 2px solid #fff;
        color: #fff;
        span{
            position: relative;
            z-index: 1;
        }
    }

    .welcome_img{
        height: 70%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img{
            width: 90%; 
            object-fit: contain;
        }
    }
}