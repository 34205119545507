.contacts_container {
  width: 100%;
  display: flex;
  position: relative;
}
.contacts_container .vector_abstract {
  width: 100%;
  height: 1800px;
  background: url(../../assets/img/content/bg-contact.png) center/cover no-repeat;
  filter: grayscale(120%) contrast(1.1);
  position: absolute;
  z-index: 0;
  bottom: 0;
  opacity: 0.06;
  left: 0;
  right: 0;
}

.contact {
  width: 100%;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px 0 80px 0;
  z-index: 1;
}
.contact .contact_us {
  width: 100%;
  padding-top: 50px;
}
.contact .contact_us h3 {
  opacity: 1;
  color: transparent;
  text-transform: lowercase;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
}
.contact .contact_us h1 {
  font-size: 50px;
  margin-bottom: 30px;
}
.contact .contact_method {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact .contact_method p {
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 0.2em;
}
.contact .contact_method p svg {
  width: 30px;
  height: 30px;
  fill: #fff;
  transform: translateY(-2px);
  transition: 0.4s ease;
}
.contact .contact_method a:hover svg {
  transition: 0.4s ease;
  transform: rotate(0deg) translateY(0px) translateX(-2px) scale(1.1);
}
.contact .contact_method span {
  text-align: left;
}
.contact .contact_method a {
  display: flex;
  gap: 0.5em;
  padding: 0;
}
.contact .contact_method a svg {
  width: 100%;
  height: 100%;
  fill: url(#gradient);
  transition: 0.4s ease;
  transform: rotate(-45deg) translateY(0px) translateX(-2px);
}
.contact .contact_method a span {
  height: 100%;
  display: flex;
  font-size: 18px;
  align-items: center;
  color: transparent;
  background-image: linear-gradient(45deg, #0494d1, #00ff7b);
  background-clip: text;
  -webkit-background-clip: text;
}
.contact .contact_method a div {
  padding: 2px;
  width: 40px;
  height: 40px;
  border: 1px solid #00ff7b;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.contact .svg_decor {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
@media screen and (min-width: 0px) {
  .contact .svg_decor {
    right: 50px;
    opacity: 0.5;
  }
}
@media screen and (min-width: 767.98px) {
  .contact .svg_decor {
    right: 100px;
    opacity: 1;
  }
}
.contact .svg_decor #gradient_contact stop {
  stop-color: #e3ecf0 !important;
}
.contact .svg_decor svg {
  width: 100%;
  height: 100%;
  fill: url(#gradient_contact);
  transition: 0.4s ease;
}
.contact .form_custom input {
  width: 100%;
  padding: 10px;
  outline: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #000;
  border-bottom: 2px solid #000;
}
.contact .form_contact {
  width: 100%;
  display: flex;
  position: relative;
  background: linear-gradient(45deg, #48f96b, #15ceb5);
  border-radius: 20px;
  padding: 10px 10px;
  gap: 1em;
}
.contact .form_contact small {
  opacity: 0.8;
  font-size: 12px;
}
.contact .form_contact h2 {
  width: 90%;
}
@media screen and (min-width: 0px) {
  .contact .form_contact h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 767.98px) {
  .contact .form_contact h2 {
    font-size: 50px;
  }
}
.contact .form_contact p,
.contact .form_contact h2 {
  color: #000;
}
.contact .form_contact .form_holder {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5em;
  z-index: 2;
  flex-direction: column;
  border: 2px solid #000;
  padding: 40px 20px;
  border-radius: 20px;
}
@media screen and (min-width: 767.98px) {
  .contact .form_contact .form_holder {
    padding-right: 30%;
  }
}
.contact .form_contact .form_holder .form-control {
  padding: 5px !important;
}
.contact .form_contact .form_holder .form-control label::after {
  background: transparent !important;
}
.contact .form_contact .form_holder .form-control label {
  background: transparent !important;
}/*# sourceMappingURL=Contact.module.css.map */